import {
  execProgressiveHandler,
  progressiveHandler,
} from "../ProgressiveHandler";
import { QueryById } from "../types";
import { cache, newgraphClient } from "..";
import { _current, current } from "./auth";
import {
  MoodReadResponse,
  UserReadPrivateResponse,
  UserReadPublicResponse,
  UserUpdateRequest,
  WattsClaimRequest,
  WattsClaimResponse,
} from "@newstackdev/iosdk-newgraph-client-js";
import { castArray } from "lodash";
import { cacheFolders } from "./folder";
import { resizeImage } from "../utils/resizeImage";
import { batchAsync } from "../utils/batchAsync";

export const filterOrderBy = {
  ["Top"]: "watts",
  ["Active"]: "updated",
};

const userQuery = (id: string | string[]) =>
  cache.user.get(id instanceof Array ? id[0] : id);
// .where("id")
// .anyOf(id instanceof Array ? id : [id])
// .first();

const topUsers = () => {
  return cache.user.toCollection().reverse().sortBy("watts");
};
const leaderBoardUsersQuery = (filter: "Active" | "Top", filterTag: string) => {
  return filter === "Active"
    ? cache.user
      .toCollection()
      .reverse()
      //@ts-ignore
      .filter((u) => u.updated && !u.username?.startsWith("test"))
      .filter((u) =>
        filterTag
          ? //@ts-ignore
          u.tags?.some((tag) => tag.value.includes(filterTag))
          : true
      )
      .sortBy("updated")
    : //@ts-ignore
    cache.user
      .toCollection()
      .reverse()
      //@ts-ignore
      .filter((u) => !u.username?.startsWith("test"))
      .filter((u) =>
        filterTag
          ? //@ts-ignore
          u.tags?.some((tag) => tag.value.includes(filterTag))
          : true
      )
      .sortBy("watts");
};
export const grants = (
  id?: string | string[],
  opts?: { sortBy?: string; reverse?: boolean }
) => {
  return cache.__EDGES
    .where("__outE")
    .startsWith(`user+${id || ""}+access+folder`)
    .toArray()

    .then((res) => {
      const _opts = {
        sortBy: opts?.sortBy ?? "created",
        reverse: opts?.reverse,
      };

      const q = cache.folder.where("id").anyOf(res.map((r) => r.to || ""));

      const maybeReversed = _opts.reverse ? q.reverse() : q;
      const sorted = maybeReversed.sortBy(_opts.sortBy);

      return sorted || [];
      // .toArray()
    })
    .catch((err) => {
      console.log(err);
      throw err;
    });
};

export const cacheUsers = async (
  users: UserReadPublicResponse | UserReadPublicResponse[]
) => {
  const ps = castArray(users);

  const toPut: UserReadPublicResponse[] = [];
  for (const user of ps) {
    const _curr = user?.id
      ? await userQuery(user?.id)
      : ([] as UserReadPublicResponse);
    const curr = _curr || {};

    const keysCount = Object.keys(user).length;
    if (curr.id && keysCount <= 2) return;

    toPut.push({
      id: curr.id || user.id || "",
      username: curr.username || user.username || "",
      ...curr,
      ...user,
    });
  }
  await cache.user.bulkPut(toPut);

  // const promises = ps.map(async (user) => {
  //     // const attachTo = [...(folders || []), ...(user.moods || [])];
  //     // attachTo && (await cachePostAttachment([post], attachTo))
  // });
};

export const cacheFoldersBatchAsync = batchAsync(cacheUsers, {
  batchSize: 100,
  maxSecondsRetention: 10,
});

const searchUsersQuery = (p: { q: string }) => {
  if (!p?.q) return cache.user.where("id").equals(-1).toArray();
  return cache.user
    .toCollection()
    .filter((u) => {
      return !![u.username, u.fullName, u.displayName, u.facebook, u.instagram]
        .filter(Boolean)
        .map((v) => {
          try {
            v =
              (v as any) instanceof Array ? (v as any as string[]).join("") : v;
            return (v || "").toLowerCase();
          } catch (ex) {
            debugger;
          }
        })
        .find((v) => v?.includes(p.q || ""));
    })
    .reverse()
    .sortBy("watts");
};

const userFolders = (id: string | string[], flags?: string) => {
  return cache.__EDGES
    .where("__outE")
    .startsWith(`user+${id || ""}+author+folder`)
    .toArray()

    .then((res) => {
      const t = cache.folder.where("id").anyOf(res.map((r) => r.to || ""));

      const p = flags
        ? t.filter((m) => flags?.split(/,/).includes((m as any).flags || []))
        : t;

      return p.toArray().then((results) => {
        return results.sort((a, b) => {
          return (
            new Date(b.created || 0).getTime() -
            new Date(a.created || 0).getTime()
          ); // Assuming `created` is a numeric timestamp property
        });
      });
    });
};

export const readUser = ({ id }: QueryById) =>
  progressiveHandler(
    // req/cache key
    { id },

    // cache query
    () => userQuery(id || ""),

    // how to fetch
    async (progress) => {
      if (!id) return progress;

      const cached = await userQuery(id || "");
      if (cached && Object.keys(cached || {}).length > 2 && cached.username)
        return await Promise.resolve(progress);

      const res = await newgraphClient.api.user.userList({
        ...(id ? { id } : {}),
      });
      if (res.data?.id) await cacheUsers(res.data);

      return progress.clone({
        ...progress,
        done: true,
      });
    },
    {
      autostart: true,
    }
  );

export const updateUser = (userData: UserUpdateRequest, profilePicture: any) =>
  progressiveHandler(
    undefined,
    () => { },
    async (progress) => {
      const hasUploadProfile = profilePicture && profilePicture.originFileObj;

      const base64thumb =
        profilePicture &&
        (profilePicture.type || "").startsWith("image") &&
        (await resizeImage(profilePicture.originFileObj));
      const thumbUrl = profilePicture ? base64thumb : "";

      const ud = profilePicture
        ? { ...userData, contentUrl: thumbUrl }
        : userData;
      await newgraphClient.api.user.userUpdate(ud);

      if (hasUploadProfile) {
        const uploadInfo = await newgraphClient.api.user.userUploadAvatarCreate(
          {
            filename: profilePicture.name,
            contentType: profilePicture.type,
          }
        );

        await fetch(uploadInfo.data.url as string, {
          method: "PUT",
          body: profilePicture.originFileObj,
        });
      }

      _current.value.contentUrl = thumbUrl || _current.value.contentUrl;

      await cacheUsers([
        {
          ...userData,
          contentUrl: thumbUrl,
          uploadState: { thumbUrl, oldContentUrl: _current.value.contentUrl },
        } as any,
      ]);

      return progress.clone({
        ...progress,
        done: true,
      });
    },
    {
      autostart: true,
    }
  );

export const listTopUsers = () =>
  progressiveHandler(
    undefined,

    topUsers,

    async (progress) => {
      const res = await newgraphClient.api.user.listTopList({
        page: progress.page.toString(),
      });
      cache.user.bulkPutDelayed(res.data.value || []);

      return {
        // ...progress,
        page: progress.page + 1,
        done: !!res.data.done,
      };
    },
    {
      autostart: true,
    }
  );

export const leaderBoardUsers = (filter: {
  filterType: "Top" | "Active";
  tagFilter: string;
}) =>
  progressiveHandler(
    filter,
    () => leaderBoardUsersQuery(filter.filterType, filter.tagFilter),
    async (progress, _cache) => {
      const res = await newgraphClient.api.user.listSearchList({
        page: progress.page.toString(),
        orderBy: filterOrderBy[filter?.filterType],
        //@ts-ignore
        tags: [filter.tagFilter],
      });
      await cache.user.bulkPutDelayed(res.data.value || []);

      return {
        ...progress,
        page: progress.page + 1,
        done: !!res.data.done,
      };
    },
    {
      autostart: true,
    }
  );

export const searchUsers = (p: { q: string }) =>
  progressiveHandler(
    p,

    () => searchUsersQuery(p),

    async (progress, _cache, p: { q: string }) => {
      if (!p?.q) return progress;

      const res = await newgraphClient.api.user.listSearchList({
        q: `*${p.q}*`,
        page: progress.page.toString(),
      });

      const cached = await searchUsersQuery(p);

      await cache.user.bulkPut(
        (res.data.value || []).map((v) => ({
          ...v,
          cached: new Date().toISOString(),
        })) || []
      );

      return {
        // ...progress,
        page: progress.page + 1,
        done: !!res.data.done,
      };
    },

    {
      throttle: {
        wait: 3000,
      },
    }
  );

export const readUserFolders = ({ id }: QueryById, flags?: string) =>
  progressiveHandler(
    { id, flags },

    () => userFolders(id || "", flags),

    async (progress) => {
      if (progress.done) return progress;

      const method = _current.value.id ? "moodsList" : "moodsPubList";
      const res = await newgraphClient.api.user[method]({
        page: progress.page.toString(), //page.toString(),
        id,
        flags,
      });

      const v = res.data?.value;
      if (v) {
        await cache.folder.bulkPut(v);
        await cache.storeEdges(
          v.map((f) => ({
            from: id,
            fromLabel: "user",
            to: f.id,
            toLabel: "folder",
            label: "author",
          }))
        );
      }

      return {
        ...progress,
        page: progress.page + 1,
        done: !!res.data.done,
      };
    },
    {
      autostart: true,
    }
  );

export const claimWatts = async (claims: WattsClaimRequest) => {
  const res = await newgraphClient.api.user.claimWattsCreate({ claims } as any);

  return res as WattsClaimResponse;
};

export const readUserGrants = ({ id }: { id?: string }) =>
  progressiveHandler(
    { id },
    () => grants(id || ""),
    async (progress, _cache, params) => {
      const res = await newgraphClient.api.user.grantsListList({
        page: progress?.page.toString(),
      } as any);
      console.log(res, "userGrant");

      const grants = res.data;

      const curr = (await execProgressiveHandler(
        current
      )) as UserReadPrivateResponse;
      // const curr = execProgressiveHandler(current, { autostart: false });
      // await currProg.value.promise;
      // const [curr] = current({ autostart: false });

      const folders = [] as MoodReadResponse[];
      const edges = [] as any[];
      grants?.value?.map((g) => {
        folders.push(g.target as MoodReadResponse);
        edges.push({
          from: curr.id,
          fromLabel: "user",
          to: g.target?.id,
          toLabel: "folder",
          label: "access",
          props: { level: g.level || "" },
        });
      });

      await cacheFolders(folders);
      await cache.storeEdges(edges);
      await cacheUsers([curr as UserReadPublicResponse]);

      return {
        ...progress,
        page: (progress.page || 0) + 1,
        done: grants?.done,
      };
    },
    {
      autostart: true,
    }
  );

const historySignal = [] as any;
export const readUserHistory = () =>
  progressiveHandler(
    {},
    historySignal, //grants(id || ""),
    async (progress, _cache, params) => {
      const res = await newgraphClient.api.user.historyList();
      console.log(res);
      historySignal.value = res.data;
      // const grants = res.data;

      // const curr = await execProgressiveHandler(current)
      // // const curr = execProgressiveHandler(current, { autostart: false });
      // // await currProg.value.promise;
      // // const [curr] = current({ autostart: false });

      // const folders = [] as MoodReadResponse[];
      // const edges = [] as any[];
      // grants.value?.map((g) => {
      //     folders.push({ id: g.target?.id || "", updated: new Date().toISOString() });
      //     edges.push({ from: curr.id, fromLabel: "user", to: g.target?.id, toLabel: "folder", label: "access", props: { level: g.level || "" } });
      // });

      // await cacheFolders(folders);
      // await cache.storeEdges(edges);

      return {
        ...progress,
        // page: (progress.page || 0) + 1,
        done: true,
      };
    },
    {
      autostart: true,
    }
  );
