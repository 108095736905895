import {
  execProgressiveHandler,
  progressiveHandler,
} from "../ProgressiveHandler";
import { QueryById } from "../types";
import { cache, EnrichedFolder, newgraphClient } from "..";
import {
  MoodCreateRequest,
  MoodCreateResponse,
  EntityAttachRequest,
  MoodReadResponse,
  PostReadResponse,
  UserReadPublicResponse,
  MoodUpdateRequest,
} from "@newstackdev/iosdk-newgraph-client-js";
import { Signal, signal } from "@preact/signals-react";
import { cachePosts } from "./post";
import { newgraphWebsocketsClient } from "../clients/newgraph-websocket";
import {
  castArray,
  get,
  groupBy,
  keyBy,
  mapValues,
  omit,
  orderBy,
  sortBy,
} from "lodash";
import { cacheUsers, readUser } from "./user";
import { ProgressEntry } from "../ProgressiveHandler/progressEntry";
import { _current } from "./auth";
import Dexie, { PromiseExtended } from "dexie";
import { uuidv4 } from "./upload/helpers";
import { batchAsync } from "../utils/batchAsync";
// import { fischerYates } from "../../../../apps/web/utils/random";

function mergeObjects(
  obj1: Record<string, any>,
  obj2: Record<string, any>
): Record<string, any> {
  // Create a new object by merging obj1 and obj2
  obj1 = obj1 || {};
  obj2 = obj2 || {};
  const merged = { ...obj1 };

  // Iterate over keys of obj2 and update merged object accordingly
  for (const key in obj2) {
    if (obj2.hasOwnProperty(key)) {
      // Choose the first non-null and non-undefined value between obj1 and obj2
      merged[key] =
        [obj2[key], obj1[key]].find(
          (v) => v != null && typeof v != "undefined"
        ) ?? obj1[key];
    }
  }

  return merged;
}

export const foldersQuery = (id: string | string[]) =>
  cache.folder
    .where("id")
    .anyOf(id instanceof Array ? id : [id])
    .toArray()
    .then((results) => {
      const sortedResults = results.sort((a, b) => {
        return (
          new Date(a.created || 0).getTime() -
          new Date(b.created || 0).getTime()
        ); // Assuming `created` is a numeric timestamp property
      });
      //console.log(sortedResults);
      return sortedResults;
    });

// .sortBy("created");
export const folderQuery = (id: string | string[]) =>
  foldersQuery(id).then((val) => (val || [])[0] || {});

export const cacheFolders = async (
  folders: EnrichedFolder | EnrichedFolder[],
  force?: boolean
) => {
  const ps = castArray(folders);
  const items = [] as MoodReadResponse[];
  const authors = {} as Record<string, UserReadPublicResponse>;
  const edges = [] as any;

  const currList = await foldersQuery(ps.map((f) => f.id || ""));
  const currDict = keyBy(currList, "id") as Record<string, MoodReadResponse>;

  ps.forEach((folder) => {
    const curr = folder.id ? currDict[folder.id] : ({} as MoodReadResponse);

    const cachedIsFuture = (curr as any)?.future;

    if (
      curr &&
      !cachedIsFuture &&
      !(new Date(folder.updated || Date.now()) >= new Date(curr.updated || 0))
    )
      return;

    const keysCount = Object.keys(folder).length;

    const isBoring =
      !force &&
      !cachedIsFuture &&
      Object.keys(folder).length == 2 &&
      folder.id &&
      folder.label;

    if (isBoring) return;

    // await cache.folder.put();

    // const attachTo = [...(folders || []), ...(post.moods || [])];
    // attachTo && (await cachePostAttachment([post], attachTo))

    //when folder had null values, it was overwriting the values of curr. MergeObject function merges 2 objects and ignores null values
    items.push(mergeObjects(omit(curr, "future"), folder));

    if (folder.author) {
      authors[folder.author?.id || ""] =
        folder.author as UserReadPublicResponse;
      edges.push({
        from: folder.author.id,
        fromLabel: "user",
        to: folder.id,
        toLabel: "folder",
        label: "author",
      });
    }
  });
  if (!items.length)
    return;

  await cache.folder.bulkPutDelayed(items);

  // pure folder updates dont have to have an author
  if (!authors.length)
    return;

  await cacheUsers(Object.values(authors));
  await cache.storeEdges(edges);

  // return Promise.all(promises)
};

export const cacheFoldersBatchAsync = batchAsync(cacheFolders, {
  batchSize: 100,
  maxSecondsRetention: 10,
});

const sumProp = (arr: any, prop: string) =>
  arr.reduce((r: number, c: any) => r + get(c, prop) || 0, 0);

const advancedSort: Record<
  string,
  (posts: PostReadResponse[]) => PostReadResponse[]
> = {
  users: (posts: PostReadResponse[]) => {
    return orderBy(
      posts,
      ["author.watts", "rating"],
      ["desc", "desc"]
    ) as PostReadResponse[];
  },
  points: (posts: PostReadResponse[]) => {
    const grouped = groupBy(posts, "author.id");
    const groupedSorted = mapValues(grouped, (vals: PostReadResponse[]) =>
      orderBy(vals, ["rating"], ["desc"])
    );

    const sortedIds = Object.keys(grouped).sort(
      (a, b) => sumProp(grouped[b], "points") - sumProp(grouped[a], "points")
    );
    return sortedIds
      .map((id: string) => groupedSorted[id])
      .flat() as any as PostReadResponse[];
  },
};

export const folderPosts = (
  id: string | string[],
  opts?: { sortBy?: string; reverse?: boolean; noTextNodes?: boolean, noPrivate?: boolean }
) => {
  const _opts = { sortBy: opts?.sortBy ?? "created", reverse: opts?.reverse };
  return cache.__EDGES
    .where("__outE")
    .startsWith(`folder+${id || ""}+attachment+post`)
    .toArray()

    .then((res) => {
      // if(_opts?.reverse)
      const q = cache.post
        .where("id")
        .anyOf(res.map((r) => r.to || ""))
        .filter((p) => {
          return (
            !p.deleted &&
            (opts?.noTextNodes
              ? (p.contentType && !p.contentType?.startsWith("text")) ||
              (p.content || "").trim().startsWith("/card")
              : true) &&
            (opts?.noPrivate && (p as any).isPrivate ? false : true)
          );
        });

      const advanced = advancedSort[_opts.sortBy];

      const maybeReversed = !!advanced ? q : _opts.reverse ? q.reverse() : q;
      const sorted = !!advanced
        ? q.toArray()
        : maybeReversed.sortBy(_opts.sortBy);

      sorted.then((r) => {
        console.log(r)
      })

      if (!advanced) return sorted;

      return sorted.then((res) => advanced(res));
      // .toArray()
    });
};

export const cachePostAttachment = async (
  posts: PostReadResponse[],
  folders: MoodReadResponse[],
  relName?: string | string[]
) => {
  relName = relName || "attachment";
  const rels = castArray(relName);
  for (const rn of rels)
    await cache.storeEdgesDelayed(
      posts
        .map((p) =>
          folders.map((f) => ({
            from: f.id || "",
            fromLabel: "folder",
            to: p.id,
            toLabel: "post",
            label: rn, // relName || "attachment",
          }))
        )
        .flat()
    );
};

export const deletePostAttachment = async (
  posts: PostReadResponse[],
  folders: MoodReadResponse[]
) => {
  await cache.storeEdgesDelayed(
    posts
      .map((p) =>
        folders.map((f) => ({
          from: f.id || "",
          fromLabel: "folder",
          to: p.id,
          toLabel: "post",
          label: "attachment",
        }))
      )
      .flat()
  );
};

const subscribed = {} as Record<string, boolean>;
export const liveSubscribe = async (folderId: string) => {
  if (subscribed[folderId]) return;

  newgraphWebsocketsClient.send({ action: "subscribe", targetId: folderId });
  subscribed[folderId] = true;
};

export const attachToFolder = async (
  post: PostReadResponse,
  folder: MoodReadResponse
) => {
  await newgraphClient.api.mood.attachPostUpdate({
    targetId: post.id || "",
    id: folder.id || "",
  });
  return await cachePostAttachment([post], [folder]);
};

export const massAttachToFolder = async (
  posts: PostReadResponse[],
  folder: MoodReadResponse
) => {
  for (const p of posts) {
    await attachToFolder(p, folder);
  }
};

export const attachToFolders = async (
  post: PostReadResponse,
  folders: MoodReadResponse[]
) => {
  await Promise.all(
    folders
      .filter((m) => m.id)
      .map((m) => {
        attachToFolder(post, m);
      })
  );
};

type AttacheableLabel = "mood" | "post" | "user";
const attachMethods = {
  mood: (params: EntityAttachRequest) =>
    newgraphClient.api.mood.attachMoodUpdate(params),
  post: (params: EntityAttachRequest) =>
    newgraphClient.api.mood.attachPostUpdate(params),
  user: (params: EntityAttachRequest) =>
    newgraphClient.api.mood.attachUserUpdate(params),
};

const attachToFolderQueue = [];

const attachToFolderBase = async (
  progress: ProgressEntry,
  params: {
    id: string; //UserReadPublicResponse | MoodReadResponse | PostReadResponse
    targetId: string;
    label: AttacheableLabel;
  }
) => {
  await attachMethods[params.label](params);
  return progress;
};

export const attachToFolderProgressive = () =>
  progressiveHandler({}, {}, (progress, _, params) =>
    attachToFolderBase(progress, params)
  );

export const attachToFolderProgressiveMulti = () =>
  progressiveHandler(
    {},
    {},
    async (
      progress,
      _,
      params: {
        id: string; // folder id
        attacheables: {
          targetId: string;
          label: AttacheableLabel;
        }[];
      }
    ) => {
      const id = params.id;
      for (const attacheable of params.attacheables) {
        await attachToFolderBase(progress, { id, ...attacheable });
        return progress;
      }

      return progress;
    }
  );

export const readFolder = ({ id }: QueryById) =>
  progressiveHandler(
    { id },

    () => folderQuery(id || ""),

    async (progress) => {
      // if (progress.done) return Promise.resolve(progress);

      const res = await newgraphClient.api.mood.moodList({ id });
      if (res.data?.id) {
        //cache.folder.put(res.data);
        await cacheFolders(res.data);
        if (!res.data.isPrivate)
          await cachePostAttachment(res.data.posts || [], [{ id }]);
      }
      return {
        done: true,
      };
    },
    {
      autostart: true,
    }
  );

export const readMultipleFolder = ({ ids }: { ids: string[] }) =>
  progressiveHandler(
    {},
    () => foldersQuery(ids),

    async (progress) => {
      // if (progress.done) return Promise.resolve(progress);

      const resArray = await Promise.all(
        ids.map((id) => newgraphClient.api.mood.moodList({ id }))
      );

      const folders = resArray.map((res) => res.data);

      folders.map(async (folder) => {
        if (folder?.id) {
          await cacheFolders(folder);
          await cachePostAttachment(folder.posts || [], [{ id: folder.id }]);
          return;
        }
      });

      return {
        done: true,
      };
    },
    {
      autostart: true,
    }
  );

export const readPosts = (
  {
    id,
    sortBy,
    reverse,
    noTextNodes,
    noPrivate,
    loadAll
  }: QueryById & { sortBy?: string; reverse?: boolean; noTextNodes?: boolean, noPrivate?: boolean, loadAll?: boolean }, // default reverse is true, that is asc order
  opts?: { readAll: boolean }
) =>
  progressiveHandler(
    { id, sortBy, reverse },

    () => folderPosts(id || "", { sortBy, reverse, noTextNodes, noPrivate }) || [],

    async (progress) => {
      if (!id) {
        return {
          ...progress,
          done: true,
        };
      }

      const go = async (_progress: ProgressEntry) => {
        const method = _current.value.username
          ? "attachmentsList"
          : "attachmentsPublicList";

        const res = await newgraphClient.api.mood[method]({
          id,
          page: _progress.page.toString() || "0",
          sortBy,
          order: (reverse ?? true) ? "desc" : "asc",
          pageSize: opts?.readAll ? 1000 : 100,
          ...(noTextNodes ? { contentType: "-text/plain" } : {})
        } as any);
        if (res.data?.value) {
          await cachePosts(res.data?.value as any);
          await cache.storeEdges(
            res.data.value?.map((p) => ({
              from: id,
              fromLabel: "folder",
              to: p.id,
              toLabel: "post",
              label: "attachment",
            }))
          );
        }

        return {
          done: !!res.data?.done,
          page: (_progress.page || 0) + 1,
        };
      };

      const nextProgress = {
        ...progress,
        page: progress.page,
        done: false,
      };

      loadAll = loadAll ?? true;

      do {
        Object.assign(nextProgress, await go(nextProgress as ProgressEntry));
        console.log(
          `readAll: done: ${nextProgress.done}, readAll: ${opts?.readAll}, page: ${nextProgress.page} ${opts?.readAll ? "Continuing" : "Not continuing"}`
        );
      } while (loadAll && !nextProgress.done && opts?.readAll);

      // await new Promise(res => setTimeout(res, 500));

      return {
        ...progress,
        ...nextProgress,
      };
    },
    {
      autostart: true,
      // throttle: {
      //     leading: 300,
      //     trailing: 300
      // }
    }
  );

export const grantWriteAccess = async ({
  user,
  folder,
}: {
  user: UserReadPublicResponse;
  folder: MoodReadResponse;
}) => {
  await newgraphClient.api.mood.accessGrantCreate({
    targetId: folder.id,
    grantee: { id: user?.id, username: user?.username },
    policy: { level: "write" },
  });

  await cache.storeEdges({
    to: folder.id,
    fromLabel: "user",
    from: user?.id,
    toLabel: "folder",
    label: "access",
    props: { level: "write" },
  });
};

// const writeAccessMultiState = signal(0);

const grantWriteAccessResult = new Signal({ redirect: "" });
export const grantWriteAccessMulti = () =>
  progressiveHandler(
    { grantWriteAceess: "multi" },
    grantWriteAccessResult,
    // writeAccessMultiState,
    async (
      progress,
      _,
      {
        users,
        folders,
        ignoreOneOnOne // for the lack of better term, if true a oneonone folder will be turned to non-oneonone instead of creating a new one when adding a 3rd person
      }: { users: UserReadPublicResponse[]; folders: MoodReadResponse[], ignoreOneOnOne?: boolean }
    ) => {
      for (const folder of folders) {
        const f = await cache.folder.get(folder.id!); //.filter(f => f.oneOnOne == id).reverse().sortBy("created").then(r => r[0]);

        if (!f || ((f.oneOnOne instanceof Array) && f.oneOnOne.length))
          continue; // weird stuff

        // const allGrantees = [...(users || [])]; //, ...(f.futureGrantees || [])];
        // f.oneOnOne = ignoreOneOnOne ? "" : allGrantees.length > 1 ? "" : (allGrantees[0]?.id);

        if (f?.future) {
          await cacheFolders({ ...f, futureGrantees: users }, true);
          continue;
        }

        await cacheFolders({ ...f, futureGrantees: [] }, true);

        const actual = {
          folder: f,
          users,
        };

        for (const user of actual.users) {
          await grantWriteAccess({ folder: actual.folder!, user });
        }
      }

      return progress;
    },
    {
      autostart: false,
    }
  );

export type MoodCreateResponsesSignal = Signal<MoodCreateResponse[]>;

const createFolderResponseSignal = signal<MoodCreateResponse[]>([]); // as DeepSignal<PostReadResponse>;
const updateFolderResponseSignal = signal<MoodReadResponse[]>([]);

export const createFolder = () =>
  progressiveHandler(
    undefined,
    createFolderResponseSignal,
    async (
      progress,
      createFolderResponseSignal,
      folderForm: MoodCreateRequest & { future?: boolean, failIfExists?: boolean }
    ) => {
      folderForm = folderForm || {};
      const _existingFuture = await (folderForm.id ? cache.folder.get(folderForm.id) : cache.folder.filter((f) => !!f.future).first());
      // .filter((f) => !!f.future)
      // .first(); // folderForm.future ? await cache.folder.filter(f => !!f.future).first() : null;

      const existingFuture = castArray(_existingFuture)[0] as EnrichedFolder;//_existingFuture as any || [])[0] as EnrichedFolder | undefined;

      // if (existingFuture && !folderForm.future) {
      //   await cacheFolders(omit(existingFuture, "future"), true); // we want createfolder to reset asap
      // }

      const _p = folderForm.future
        ?
        {
          data:
            existingFuture || {
              future: true,
              id: uuidv4(),
              author: _current.value,
              title: folderForm.title,
              isPrivate: true,
            }

        }
        : await (
          async () => {
            try {
              return await newgraphClient.api.mood.moodCreate(
                omit(folderForm, ["future", "futureGrantees", "author"])
              ) as { data: EnrichedFolder };
  
            } catch (ex) {
              console.log(ex);
              if(folderForm.failIfExists !== false) 
                throw ex;
            }
         }
        )()
      const p = _p ? _p?.data : {};

      await cacheFolders(p); //, true);


      createFolderResponseSignal.value = [
        p
      ];

      if (!folderForm.future && existingFuture && existingFuture.futureGrantees?.length) {
        (execProgressiveHandler(grantWriteAccessMulti, { users: existingFuture.futureGrantees, folders: [p] }))
      }


      // createFolderResponseSignal.value = {};

      // uploadProgressSignal.value = { [p.]: { id: p.data.id || "", progress: "processing" }, ...uploadProgressSignal.value };

      return progress;
    }
  );

export const updateFolder = () =>
  progressiveHandler(
    undefined,
    updateFolderResponseSignal,
    async (
      progress,
      updateFolderResponseSignal,
      folderForm: MoodUpdateRequest
    ) => {
      let cached = await cache.folder.get(folderForm.id!);

      if (cached?.future) {
        await execProgressiveHandler(createFolder, { ...folderForm, failIfExists: false });
        cached = await cache.folder.get(folderForm.id!); // no longer future
      }

      const p = !cached?.future
        ? await newgraphClient.api.mood.moodUpdate(folderForm)
        : { data: folderForm };

      await cache.folder
        .where("id")
        .equals(p.data.id || "")
        .modify({ ...p.data });

      updateFolderResponseSignal.value = [
        ...updateFolderResponseSignal.value,
        p.data,
      ];

      return progress;
    }
  );

// const signal = signal([])

export const grantees: (
  id?: string | string[],
  opts?: { sortBy?: string; reverse?: boolean }
) => PromiseExtended<UserReadPublicResponse[]> =
  (id, opts) => {
    // const fpp = fp.then((f) => {

    //   if (f?.future)
    //     return f.futureGrantees || [];
    // });
    // const r = await fpp;
    // if (r)
    //   return fpp;

    return cache.folder.get(id as string)
      .then(f => {
        if (f?.future)
          return Dexie.Promise.resolve(f.futureGrantees || []);

        return cache.__EDGES
          .where("__inE")
          .startsWith(`folder+${id || ""}+access+user`)
          .toArray()

          .then((res) => {
            const result = res
              .sort((a, b) => (a as any)?.props?.updated < (b as any)?.props?.updated ? -1 : 1)
              .map((item) => ({ id: item.from }))
              .filter((u) => u?.id);

            if (res.length != result.length)
              console.warn("Some grantees were filtered for not having id");

            return cache.user.bulkGet(result.map((r) => r.id!))
              .then(r1 => {
                return Dexie.Promise.resolve(r1.filter(Boolean) as UserReadPublicResponse[])
              });

          })
          .catch((err) => {
            console.log(err);
            throw err;
          });
      }
      )
  };

export const readFolderGrantees = ({ id }: { id?: string }) =>
  progressiveHandler(
    { id },
    () => grantees(id || "", { reverse: true }),
    async (progress, _cache, params) => {
      id = id || params?.id;
      if (!id) return progress;

      const res = await newgraphClient.api.mood.accessGranteesList({
        id,
        page: progress?.page.toString(),
      } as any);
      console.log(res);

      const grants = res.data;

      const users = [] as UserReadPublicResponse[];
      const edges = [] as any[];
      grants.value?.map((g) => {
        users.push({
          id: g.grantee?.id || "",
          updated: new Date().toISOString(),
        });
        edges.push({
          to: id,
          fromLabel: "user",
          from: g.grantee?.id || "",
          toLabel: "folder",
          label: "access",
          props: { level: g.level || "", updated: g.updated },
        });
      });

      await cacheUsers(users);
      await cache.storeEdges(edges);

      return {
        ...progress,
        page: (progress.page || 0) + 1,
        done: grants?.done,
      };
    },
    {
      autostart: true,
    }
  );

export const topFolders = (
  id?: string | string[],
  opts?: { sortBy?: string; reverse?: boolean }
) => {
  return (
    cache.folder
      // .where("__inE")
      .filter((o) => !(o as any).isPrivate)
      // .startsWith(`folder+${id || ""}+access+user`)
      .toArray()

      .then((res) => {
        // const _opts = { sortBy: opts?.sortBy ?? "watts", reverse: opts?.reverse }

        // // const q =
        // //     cache
        // //         .user
        // //         .where("id")
        // //         .anyOf(res.map(r => r.from || ""));

        // // const maybeReversed = _opts.reverse ? q.reverse() : q;
        // // const sorted = _opts.sortBy ? maybeReversed.sortBy(_opts.sortBy) : maybeReversed.toArray();

        // // const t = sorted.then((res) => {
        // //     console.log(res);
        // //     return res;
        // // });

        // const result = res.map(item => ({ id: item.from })).filter(u => u?.id)

        // if (res.length != result.length)
        //     console.warn("Some grantees were filtered for not having id")

        // return result;//sorted || [];
        // .toArray()
        return res;
      })
      .catch((err) => {
        console.log(err);
        throw err;
      })
  );
};

export const readTopFolders = (params?: { page?: number }) =>
  progressiveHandler(
    {},
    () => topFolders(),
    async (progress, _cache, _params) => {
      const pg = Math.max(progress.page, params?.page || 0);
      try {
        const r = await newgraphClient.api.mood.listTopList({
          page: progress.page.toString(), //params?.page?.toString() || _page,
        });

        const folders = r.data.value || [];
        await cacheFolders(folders);

        return {
          ...progress,
          page: progress.page + 1,
          done: r.data.done,
        };
      } catch (ex) {
        console.log(ex);
        throw ex;
      }
    },
    {}
  );

const oneOneOneFolderQuery = (id: string) => {
  return cache.folder
    .filter((f) => {
      return (f.oneOnOne as any) instanceof Array &&
        (f.oneOnOne as string[]).includes(id) &&
        (f.oneOnOne as string[]).includes(_current.value?.id!) &&
        !f.deleted;
    })
    .reverse()
    .sortBy("created")
    .then((r) => r[0]);
  // return f;
};

export const getOneOnOneFolder = (params: { id?: string; username?: string }) =>
  progressiveHandler(
    params,
    () => oneOneOneFolderQuery(params.id || ""), //grants(id || ""),
    async (progress, _cache, _params) => {
      if (!params.id) return Promise.resolve(progress);

      const currUser = _current.value.id;
      const targetUser = params.id;

      const oneOnOneId = [currUser, targetUser].sort().join("_");

      const existingFromCache = await cache.folder.get(oneOnOneId);//await oneOneOneFolderQuery(params.id);
      if (
        0 &&
        existingFromCache?.id &&
        !existingFromCache.future &&
        (existingFromCache.oneOnOne instanceof Array) &&
        existingFromCache.oneOnOne.includes(params.id)
      )
        return progress;

      const existing = await newgraphClient.api.mood.oneononeList({
        id: params.id,
      });

      const u = await cache.user.get(params.id); // we can assume it's in the cache because the current user was looking at the other user in ui

      const chatTitle = `${_current.value.username} - ${u!.username}`;

      // const dt = existing.data || ;

      const brandNew = existing.data
        ? { data: null }
        : {
          data: {
            title: chatTitle,
            defaultView: "chat",
            isPrivate: true,
            id: oneOnOneId
          }
        };


      if (brandNew.data) {
        // await cacheFolders([{ ...brandNew.data, oneOnOne: params.id, future: true, futureGrantees: [{ id: params.id }] }]);

        // await newgraphClient.api.mood.moodCreate(brandNew.data as any);

        // await grantWriteAccess({
        //   user: { id: params.id },
        //   folder: brandNew.data,
        // });
      }

      const f = existing.data || brandNew.data;

      await cacheFolders([{ ...f, author: {} }]); //, futureGrantees: brandNew.data ? [{ id: params.id }] : [] }]);
      // cache.folder.put()

      execProgressiveHandler(readFolderGrantees, { id: oneOnOneId })

      // alert("Coming soon");
      await Promise.resolve();
      return progress;
    }
  );

// export const folderPosts1 = (id: string | string[]) => {
//   return cache.__EDGES
//     .where("__outE")
//     .startsWith(`folder+${id || ""}+attachment+post`)
//     .toArray()

//     .then((res) => {
//       return cache.post
//         .where("id")
//         .anyOf(res.map((r) => r.to || ""))
//         .reverse()
//         .sortBy("created");
//       // .toArray()
//     });
// };
