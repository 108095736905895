const win =
  typeof window != "undefined" ? (window as any) : ({ location: {} } as any);

export const actualCurrentHost = win.location?.hostname;
export const isLocalhost = ["127.0.0.1", "localhost", "172.20.10.2"].includes(
  actualCurrentHost
);

export const getCurrentHost = (localhostHost: string) => {
  return actualCurrentHost;
  // if (!isLocalhost) return actualCurrentHost;

  // const lh = localhostHost.split(/,/)[0]; // assume could be a list of hosts
  // return lh;
};

export const replaceHost = (host?: string) =>
  `https://${host || win.location.host}${win.location.pathname}${
    win.location.search ? "?" + win.location.search : ""
  }`;

// if (win.location.protocol != "https:" && !isLocalhost) (win.location as any) = replaceHost();
// `https://${win.location.host}${win.location.pathname}${win.location.search ? "?" + win.location.search : ""}`;


export const ensureCanonicalHost = (
  canonicalHosts: Record<string, string | undefined> = {},
  stage: string
) => {
  const canonicalHostsForStage: string[] = (canonicalHosts[stage] || "")
    .split(/,/)
    .filter(Boolean);
  if (
    !isLocalhost &&
    canonicalHostsForStage.length &&
    !canonicalHostsForStage.includes(actualCurrentHost)
  )
    (win.location as any) = `https://${canonicalHostsForStage[0]}${win.location.pathname}${
      win.location.search ? "?" + win.location.search : ""
    }`;
};

export const ensureProtocol = () => {
  if (win.location?.protocol != "https:" && !isLocalhost)
    (win.location as any) = replaceHost();
};

export const ensureProtocolAndHost = (
  canonicalHosts: Record<string, string | undefined> = {},
  stage: string
) => {
  // ensureProtocol();
  ensureCanonicalHost(canonicalHosts, stage);
};

import { env } from "./env";

// const env = env

const appDomains = {
  "eu-dev": env.REACT_APP_IOSDK_APP_DOMAIN_DEV,
  "eu-prod": env.REACT_APP_IOSDK_APP_DOMAIN_PROD, // || env.REACT_APP_IOSDK_APP_DOMAIN,
};

export const canonicalHosts = {
  "eu-dev":
    env.REACT_APP_IOSDK_APP_DEV_CANONICAL_HOSTS ||
    env.REACT_APP_IOSDK_APP_DEV_HOST,
  // "eu-sit": env.REACT_APP_IOSDK_APP_SIT_CANONICAL_HOSTS || env.REACT_APP_IOSDK_APP_SIT_HOST,
  "eu-prod":
    env.REACT_APP_IOSDK_APP_PROD_CANONICAL_HOSTS ||
    env.REACT_APP_IOSDK_APP_CANONICAL_HOSTS ||
    env.REACT_APP_IOSDK_APP_PROD_HOST ||
    env.REACT_APP_IOSDK_APP_HOST,
};

const localhostHost =
  env.REACT_APP_IOSDK_APP_LOCALHOST_HOST || canonicalHosts["eu-prod"];
export const currentHost = getCurrentHost(localhostHost || "");

if (currentHost == "app.newlife.io") {
  window.location.host = "os.newcoin.org";
}
