import { Api } from "@newstackdev/iosdk-newgraph-client-js";
export class CreatorApi extends Api<{ token: string }> { }

import { ErrorResponse, UserReadPrivateResponse } from "@newstackdev/iosdk-newgraph-client-js";


// const baseUrl = newlifeBaseUrl; //"https://api-eu-sit.newlife.io/creator";

const PATHS_TO_AUTH = [
  "/v1/mood/oneonone"
];
const requiresAuth = (url: string) => {
  const path = new URL(url).pathname;
  return PATHS_TO_AUTH.find(p => path.startsWith(p));
}

const authFetch: typeof fetch = async (url, init) => {
  const res = await fetch(url, init);
  if (
    res.status == 401 &&
    init?.method &&
    (!(["GET", "OPTIONS"].includes(init.method)) || requiresAuth(url.toString()))
  ) {
    console.log("Auth missing!");
    window?.localStorage.setItem("pre-redirect", window.location.href);
    window.location.href = "/connect?login=true"
  }

  return res as any;

  // try {
  //   const res = await new Promise((res, rej) => {
  //     fetch(a, b)
  //     .then(res)
  //     .catch((ex) => {

  //     }); // as any as Promise<ReturnType<typeof fetch>>;
  //   return res as any;
  // } catch (ex) {
  //   }
  // }
}

const placebo = {};
export const NewgraphApi = (() => {
  let _api: CreatorApi = new Proxy(placebo, { get(target, prop, receiver) { console.warn("Client PLACEBO proxy ivoked", target, prop, receiver); return {} } }) as any;
  let _token = "";

  return {
    initialize(baseUrl: string) {
      _api = new CreatorApi({
        baseUrl,
        securityWorker: (securityData: { token: string } | null) => {
          return !securityData ? {} : { headers: { Authorization: securityData.token } };
        },
        customFetch: authFetch as typeof fetch
      });
      return _api;
    },
    getCurrentToken() {
      return _token;
    },
    updateToken(token: string) {
      _token = token;
      _api.setSecurityData({ token });
    },
    async authorize(): Promise<UserReadPrivateResponse> {
      try {
        const r = await _api.user.currentList();
        return r.data;
      } catch (_ex) {
        const ex: { error: ErrorResponse } = _ex as any;
        throw ex;
      }
    },
    get api() {
      return _api
    }
  };
});
