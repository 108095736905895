import {
  MoodReadResponse,
  PostReadResponse,
  UserReadPublicResponse,
} from "@newstackdev/iosdk-newgraph-client-js";
import Dexie from "dexie";
import { castArray } from "lodash";

// Dexie.delete('iosdk-cache');

// type IGraphObject = { id: string };
type IGraphEdge = {
  id?: string;
  fromLabel?: string;
  toLabel?: string;
  from?: string;
  to?: string;
  value?: any;
  label: string;
  props?: any;
};
type IGraphEdgeEntry = IGraphEdge & {
  cached: string;
  __outE: string;
  __inE: string;
};

const EDGES_TABLE_NAME = "__EDGES";

const makeEdgeEntry = ({
  id,
  fromLabel,
  toLabel,
  label,
  from,
  to,
  props,
}: IGraphEdge) => {
  const __outE = [fromLabel, from, label, toLabel, to].join("+");
  const __inE = [toLabel, to, label, fromLabel, from].join("+");

  return {
    __outE,
    __inE,
    label,
    fromLabel,
    from: from,
    toLabel,
    to: to,
    cached: new Date().toISOString(),
    id: id || __outE,
    props,
  };
};

import { Counters } from "./utils/counters";
import { XPromise } from "./utils/XPromise";

export interface DelayedTable<T, U> extends Dexie.Table<T, U> {
  bulkAddDelayed: Dexie.Table["bulkAdd"];
  bulkPutDelayed: Dexie.Table["bulkPut"];
}

const MAX_TRANSACTIONS = 20;
class _Store extends Dexie {
  [EDGES_TABLE_NAME]!: DelayedTable<IGraphEdgeEntry, number>;
  counters = Counters();
  // promises = {} as Record<string, Promise<any>>;
  queue: XPromise<any>[] = [];
  allxp?: XPromise<any>;
  lastFlush: { time: number; queueLength: number } = {
    time: 0,
    queueLength: 0,
  };
  enqueue() {
    if (!this.allxp) this.allxp = new XPromise();

    const xp = new XPromise();
    this.queue.unshift(xp);
    return xp;
  }
  dequeue(xp?: XPromise) {
    // if (xp) {
    //     const nq = this.queue.filter(p => p != xp);
    //     this.queue = nq;
    // }

    const nxp = this.queue.shift();
    if (!nxp) {
      this.allxp?.resolve(null);
      this.allxp = undefined;
      return;
    }

    nxp.resolve(null);

    return nxp;
  }
  block() {
    const currCount = this.counters.get("transactions");

    const xp = this.enqueue();

    if (currCount < MAX_TRANSACTIONS) {
      this.dequeue();
    }

    return xp;
  }
  unblock(xp?: XPromise) {
    this.counters.decrement("transactions");
    const nxp = this.dequeue();
    if (nxp) this.counters.increment("transactions");
    return xp;
  }
  async flushQueue(minItems?: number) {
    const stats = () => {
      const c = this.counters.get("transactions");
      const q = this.queue.length;
      return {
        currTc: c,
        queueTc: q,
        totalTc: c + q,
      };
    };

    const { currTc, queueTc, totalTc } = stats();

    if (minItems && totalTc < minItems) {
      console.log("No need to flush:", totalTc, "items in progress/queue");
      return Promise.resolve();
    }
    const xp = new XPromise();
    this.lastFlush.time = Date.now();
    this.lastFlush.queueLength = totalTc;

    const int = setInterval(() => {
      const { currTc, totalTc } = stats();

      console.log(
        "Flushing queue. Transactions:",
        currTc,
        "Queue:",
        this.queue.length
      );
      if (!currTc && !this.queue.length) {
        clearInterval(int);
        xp.resolve();
      } else {
        if (
          this.lastFlush.queueLength == totalTc &&
          Date.now() - this.lastFlush.time > 5000
        ) {
          this.unblock();
          this.lastFlush.time = Date.now();
        }
      }
      this.lastFlush.queueLength = totalTc;
    }, 1000);

    return xp;
  }
  // async _flushQueue(xp: XPromise) {
  //     if (this.allxp) {
  //         this.allxp;
  //     }
  //     console.log("Done flushing queue:", this.queue.length)
  //     return Promise.resolve();
  // }
  constructor(
    name: string = "iosdk-cache",
    stores: Record<string, DelayedTable<any, any>>
  ) {
    super(name);

    //
    // Define tables and indexes
    // (Here's where the implicit table props are dynamically created)
    //
    this.version(1).stores({
      ...stores,
      [EDGES_TABLE_NAME]: "++id,__outE,__inE,updated",
    });

    for (const tn of Object.keys(stores)) {
      const t = (this as any)[tn] as any;
      (t as any).bulkAddDelayed = async (a: any, b: any, c: any) => {
        // await this.block();
        const res = await t.bulkAdd(a, b, c);
        // this.unblock();

        return res;
      };
      (t as any).bulkPutDelayed = async (a: any, b: any, c: any) => {
        // await this.block();
        if (!a?.length) return;

        try {
          const res = await t.bulkPut(a, b, c);
          return res; // t.bulkPut(a, b, c);
        } catch (ex) {
          console.error(ex);
          debugger;
        }

        // this.unblock();
      };
    }
  }

  async storeEdgesDelayed(edges: IGraphEdge[] | IGraphEdge) {
    // await this.block();
    try {
      edges = castArray(edges);
      return this[EDGES_TABLE_NAME].bulkPut(edges.map(makeEdgeEntry));
    } catch (ex) {
      throw ex;
    } finally {
      // this.unblock();
    }
  }

  async storeEdges(edges: IGraphEdge[] | IGraphEdge) {
    try {
      edges = castArray(edges);
      return this[EDGES_TABLE_NAME].bulkPut(edges.map(makeEdgeEntry));
    } catch (ex) {
      throw ex;
    }
  }
  async deleteEdges(edges: number | number[]) {
    edges = castArray(edges);
    await this[EDGES_TABLE_NAME].where("id").anyOf(edges).delete();
  }
  storeEdge({ id, fromLabel, toLabel, label, from, to, props }: IGraphEdge) {
    if (!from || !to) {
      console.warn("cache2: attempted to cache a bad edge, skipping");
      return;
    }

    return this[EDGES_TABLE_NAME].put(
      makeEdgeEntry({ id, fromLabel, toLabel, label, from, to, props })
    );
  }
}

export const Store = <T>(
  name: string = "iosdk-cache",
  stores: Record<string, any>
) => {
  return new _Store(name, stores) as T & _Store;
};
