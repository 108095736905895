export const env = {
  BROWSER: "none",

  REACT_APP_IOSDK_APP_NAME: "newlife",

  REACT_APP_IOSDK_APP_DOMAIN_DEV: "life.nco",
  REACT_APP_IOSDK_APP_DOMAIN_PROD: "life.nco",

  REACT_APP_IOSDK_APP_DEV_HOST: "app-dev.newlife.io",
  REACT_APP_IOSDK_APP_SIT_HOST: "test.newlife.io",
  REACT_APP_IOSDK_APP_PROD_HOST:
    "localhost,os.newcoin.org,172.20.10.2,web.newos.computer", // app.newlife.io,

  REACT_APP_IOSDK_APP_DEV_CANONICAL_HOSTS: "app-dev.newlife.io",
  REACT_APP_IOSDK_APP_PROD_CANONICAL_HOSTS: "os.newcoin.org,web.newos.computer", // app.newlife.io,
  REACT_APP_IOSDK_APP_HOST: "",

  REACT_APP_IOSDK_APP_CANONICAL_HOSTS: "os.newcoin.org,web.newos.computer",

  REACT_APP_IOSDK_APP_DAO_DOMAIN_DEV: "dx.io",
  REACT_APP_IOSDK_APP_DAO_DOMAIN_PROD: "testaaab1.io",

  REACT_APP_IOSDK_DISPLAY_DAO_DOMAIN: "life.nco",

  // the host localhost will use implying stage ant authorizer
  REACT_APP_IOSDK_APP_LOCALHOST_HOST: "web-dev.newlife.io",

  REACT_APP_PROD_FIREBASE_API_KEY: "AIzaSyAv5KoJ2S0ZCj-n45hILx7XsTT4irt6w8c",
  REACT_APP_PROD_FIREBASE_AUTH_DOMAIN: "newlifeio-prod.firebaseapp.com",
  REACT_APP_PROD_FIREBASE_APP_ID: "1:666370792765:web:02c694986693a8ae54f954",
  REACT_APP_PROD_FIREBASE_MESSAGING_SENDER_ID: "666370792765",
};
